/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { Fetish } from '@/store/fetish/types';

const namespace: string = 'fetish';

export default class FetishVideoChosenPage extends Vue {
	@State('fetish') fetish: Fetish | undefined;
	@Getter('getFetishCategory', {namespace}) getFetishCategory: any;
	@Getter('getNameModel', {namespace}) getNameModel: any;
	@Getter('getFetishModelVideo', {namespace}) getFetishModelVideo: any;
	@Getter('getBalance', {namespace}) getBalance: any;

	fetishVideoChoicen() {
		this.$router.push({ name: 'fetish-video-viewing' }).then(r => r);
	}

	selectedFetishVideo() {
		this.$router.push({ name: 'fetish-video-chosen' }).then(r => r);
	}
}
